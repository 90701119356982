import { Record } from 'immutable';

export default class PromoRecord extends Record({
  id: null,
  max_net_duration: null,
  max_net_payment: null,
  status: '',
  co_branded: false,
  merchant_id: null,
}) {}
