import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Error from '../../shared/Error';
import Loading from '../../shared/Loading';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import ProgressBars from '../../shared/ProgressBars';
import Tooltip from '../../shared/Tooltip';
import usePromo from '../../../hooks/usePromo';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSessionEntity from '../../../hooks/useSessionEntity';
import { asyncActions } from '../../../actions/general';
import { emitEvent } from '../../../lib/utils/modal';
import { formatCurrency, formatPercent } from '../../../lib/utils/formatters';
import { formStyles } from '../../../lib/constants/SiteVariables';
import { getCompany } from '../../../actions/companies';
import { getRoute, loading } from '../../../actions/sessions';
import {
  confirmShoppingCart,
  getLoanAgreement,
} from '../../../actions/shopping_carts';

const numPayments = {
  2: { term: 'TWO', period: 'monthly' },
  3: { term: 'THREE', period: 'monthly' },
  4: { term: 'FOUR', period: 'biweekly' },
  6: { term: 'SIX', period: 'monthly' },
  9: { term: 'NINE', period: 'monthly' },
  12: { term: 'TWELVE', period: 'monthly' },
  24: { term: 'TWENTY FOUR', period: 'monthly' },
};

export default function Confirm({ location }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const session = useSelector((state) => state.sessions.get('session'));
  const cart = useSessionEntity('shopping_cart');
  const company = useSessionEntity('company');
  const merchant = useSessionEntity('merchant');
  const user = useSessionEntity('user');
  const promo = usePromo();
  const [loanAgreement, setLoanAgreement] = useState(null);
  const [agreed, setAgreed] = useState(false);
  const [rechargeAgreed, setRechargeAgreed] = useState(false);
  const [valid, setValid] = useState(true);
  const [rechargeValid, setRechargeValid] = useState(true);

  const isInstore = session.custom_flags.includes('instore');
  const validAgreements = isInstore ? agreed && rechargeAgreed : agreed;
  const defaultTerm = company?.recharge_settings?.find((s) => {
    return s.get('merchant_id').toString() === merchant.id.toString();
  });

  useEffect(() => {
    if (parseFloat(cart?.grand_total) === 0 || cart?.num_payments <= 0) {
      return history.push('/error');
    }

    dispatch(loading(true));
    dispatch(asyncActions(getLoanAgreement(cart)))
      .then((res) => setLoanAgreement({ __html: res.attributes.data }))
      .then(() => dispatch(loading(false)));
  }, []);

  useEffect(() => {
    if (agreed) setValid(true);
    if (rechargeAgreed) setRechargeValid(true);
  }, [agreed, rechargeAgreed]);

  const save = (e) => {
    e.preventDefault();

    if (validAgreements) {
      emitEvent(
        user,
        {
          path: location.pathname,
          selected_term: cart.num_payments,
        },
        cart,
      );

      dispatch(loading(true));
      dispatch(
        asyncActions(
          confirmShoppingCart(cart, agreed, rechargeAgreed),
          getCompany(session.depId('company')),
          getRoute(),
        ),
      ).then(() => dispatch(loading(false)));
    } else {
      if (!agreed) setValid(false);
      if (!rechargeAgreed) setRechargeValid(false);
    }
  };

  const headerText = () => {
    if (cart.using_virtual_card) {
      return (
        <p>
          Confirm your{' '}
          <span className="font-semibold text-primary-blue mx-1">
            {formatCurrency(cart.grand_total)}
          </span>{' '}
          order to enjoy 0% interest for {promo.get('max_net_duration')} days
          and receive your virtual card.
        </p>
      );
    } else {
      return cart.pending_completion ? (
        <p>
          Complete your
          <span className="font-semibold text-primary-blue mx-1">
            {formatCurrency(cart.grand_total)}
          </span>
          order with
          <span className="whitespace-nowrap mx-1">{merchant.name}</span>
        </p>
      ) : (
        <p className="font-semibold text-primary-blue mx-1">
          Complete your order
        </p>
      );
    }
  };

  useRouteStatus(location);

  if (!(merchant && cart)) {
    return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;
  }

  return (
    <div id="content" role="main" className="mx-6 sm:mx-auto md:mb-6">
      <ProgressBars />
      <div className="mt-6 mb-2">
        <div className="text-xl sm:text-4xl font-extrabold">
          Confirm & Review
        </div>
      </div>
      <div className="tracking-wide mb-4 sm:mb-6 font-light text-sm sm:text-lg">
        {headerText()}
      </div>

      <Error />

      <div className="rounded-lg bg-white border border-tertiary-gray shadow">
        <div className="flex flex-col w-full items-center justify-center p-3 sm:p-6">
          <div className="flex items-center">
            <h3 className="truncate text-center sm:text-left sm:text-lg sm:text-xl">
              {cart.num_payments > 1 && (
                <>
                  <strong className="font-bold mx-1">
                    {numPayments[cart.num_payments].term}
                  </strong>
                  {numPayments[cart.num_payments].period} payments of
                  <strong className="font-bold mx-1 block xxs:inline">
                    {formatCurrency(cart.payment_amount)}
                  </strong>
                </>
              )}
              {cart.num_payments === 1 && (
                <span className="text-center">
                  <strong className="font-bold">One</strong> single payment of
                  <strong className="font-bold mx-1 block xxs:inline">
                    {formatCurrency(cart.payment_amount)}
                  </strong>
                </span>
              )}
            </h3>
          </div>
          <div className="sm:mt-1 text-sm text-center">
            {cart.num_payments !== 4 && (
              <>
                <div className="my-2 font-semibold">
                  First payment due {promo.get('max_net_payment') + 1} days
                  after your order is processed
                </div>
                {cart.processing_fee > 0.0 && (
                  <div>
                    <Tooltip
                      tooltipText={`For all Credit Key Anywhere orders there is a ${formatPercent(
                        cart?.processing_fee * 100,
                      )} service fee. Because your merchant did not elect to cover the fee, it is added on to your order amount.`}
                      triggerClass="px-1 hover:cursor-pointer hover:text-secondary-gray text-xs sm:text-base"
                      containerClass="p-3 bg-gray-100 max-w-52 sm:max-w-96 tracking-wide text-xs sm:text-base"
                    >
                      This order includes a{' '}
                      {formatPercent(cart?.processing_fee * 100)} Service Fee
                      <FontAwesomeIcon
                        icon={['fal', 'info-circle']}
                        className="ml-1 mt-0.5"
                      />
                    </Tooltip>
                  </div>
                )}
              </>
            )}
            {cart.num_payments === 4 && (
              <div className="mt-2">First payment due today</div>
            )}
          </div>
        </div>
        <div className="flex bg-success rounded-b-lg">
          <div className="flex w-0 flex-1 divide-x divide-white">
            <div
              className={classNames(
                'relative tracking-wider inline-flex flex-col w-0 flex-1 items-center justify-center gap-y-0.5 py-4 px-1 text-white',
                cart.processing_fee > 0.0 ? 'text-sm' : 'text-xs sm:text-base',
              )}
            >
              <p className="text-center">Total Interest</p>
              <div>
                {formatCurrency(
                  cart.approved_terms
                    ?.find((t) => t.get('term') === cart.term_length)
                    ?.get('total_interest'),
                )}
              </div>
            </div>
            {cart.processing_fee > 0.0 && (
              <div className="relative tracking-wider inline-flex flex-col w-0 flex-1 items-center justify-center gap-y-0.5 py-4 px-1 text-xs text-white">
                <p className="text-center">Service Fee</p>
                <div>{formatCurrency(cart.processing_fee_amount)}</div>
              </div>
            )}
            <div
              className={classNames(
                'relative tracking-wider inline-flex flex-col w-0 flex-1 items-center justify-center gap-y-0.5 py-4 px-1 text-white',
                cart.processing_fee > 0.0
                  ? 'text-sm'
                  : 'text-xs mobile:text-base',
              )}
            >
              <p className="text-center">Total Amount</p>
              <div>{formatCurrency(cart.total_amount_paid)}</div>
            </div>
          </div>
        </div>
      </div>

      {!defaultTerm && (
        <div className="text-center my-4 text-sm 3xs:text-base">
          <p>
            Not what you want?
            <Link
              className="font-avenir-black text-primary-blue mx-1"
              to="/purchase/terms"
            >
              Select a different term
            </Link>
          </p>
        </div>
      )}

      <div
        id="loan-agreement"
        className="my-2 form-control block w-full p-3 sm:p-4 text-xs sm:text-sm text-secondary-gray bg-white bg-clip-padding border rounded-lg border-tertiary-gray rounded transition ease-in-out m-0 h-72 max-h-72 overflow-y-scroll"
        dangerouslySetInnerHTML={loanAgreement}
      />

      <div className="relative flex flex-col items-start my-2">
        <div className="flex items-center gap-3 m-2">
          <div className="flex h-6 items-center">
            <input
              id="agree"
              name="agree"
              onChange={() => setAgreed(!agreed)}
              value={agreed}
              checked={agreed}
              aria-describedby="agree-checkbox"
              type="checkbox"
              className={classNames(
                'h-3 sm:h-4 w-3 sm:w-4 rounded border-tertiary-gray focus:ring-primary-blue mt-1 xs:mt-0',
                { 'border-error': !valid },
              )}
            />
          </div>
          <div className="text-xs sm:text-sm leading-2">
            <label htmlFor="agree">
              I have read and agree to the Business Loan Agreement, the Guaranty
              and all schedules and disclosures provided above.
            </label>
          </div>
        </div>
        {!valid && <div className={formStyles.validation}>* Required</div>}
      </div>
      {isInstore && (
        <div className="relative flex flex-col items-start mb-2">
          <div className="flex items-center gap-3 mx-2">
            <div className="flex h-6 items-center">
              <input
                id="recharge_agree"
                name="recharge_agree"
                onChange={() => setRechargeAgreed(!rechargeAgreed)}
                value={rechargeAgreed}
                checked={rechargeAgreed}
                aria-describedby="recharge-agree-checkbox"
                type="checkbox"
                className={classNames(
                  'h-3 sm:h-4 w-3 sm:w-4 rounded border-tertiary-gray focus:ring-primary-blue mt-1 xs:mt-0',
                  { 'border-error': !rechargeValid },
                )}
              />
            </div>
            <div className="text-xs sm:text-sm leading-2">
              <label htmlFor="recharge_agree">
                I agree to opt into purchases with {merchant.name} using my
                selected default term
              </label>
            </div>
          </div>
          {!rechargeValid && (
            <div className={formStyles.validation}>* Required</div>
          )}
        </div>
      )}

      <PrimaryButton
        buttonText={
          !cart.using_virtual_card
            ? 'Confirm terms & Review Order'
            : 'Confirm terms & Get Virtual Card'
        }
        buttonColor="bg-primary-blue"
        hoverClass="hover:bg-primary-blue-hover"
        disabled={isLoading}
        isLoading={isLoading}
        onClick={save}
      />
    </div>
  );
}
