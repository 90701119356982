import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { fromJS, List, Map } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loading from '../../shared/Loading';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import ProgressBars from '../../shared/ProgressBars';
import TermItem from '../../shared/TermItem';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSessionEntity from '../../../hooks/useSessionEntity';
import {
  daysAsMonths,
  formatCurrency,
  formatPercent,
} from '../../../lib/utils/formatters';
import { formStyles } from '../../../lib/constants/SiteVariables';
import { getApprovedTerms, updateRechargeTerm } from '../../../actions/companies';
import { getRoute, loading } from '../../../actions/sessions';

export default function RechargeSettings({ location }) {
  const dispatch = useDispatch();
  const company = useSessionEntity('company');
  const merchant = useSessionEntity('merchant');
  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const [termData, setTermData] = useState([]);
  const [agreed, setAgreed] = useState(false);
  const [valid, setValid] = useState(true);
  const [selectedTerm, setSelectedTerm] = useState({
    term: null,
    term_period: null,
    payment: null,
  });

  useRouteStatus(location);

  useEffect(() => {
    if (!company || !merchant) return;

    dispatch(loading(true));
    dispatch(getApprovedTerms(company.id, merchant.id))
      .then((res) => setTermData(fromJS(res.data.attributes.terms)))
      .finally(() => dispatch(loading(false)));
  }, []);

  useEffect(() => {
    if (!List.isList(termData)) return;

    const existingSetting = company.recharge_settings.find((s) => {
      return s.get('merchant_id').toString() === merchant.id.toString();
    });

    // Set initial selected term. It can be any term row if some are
    // unavailable or a previsouly selected one.
    const firstAvailable = termData.find((t) => {
      return (
        (!existingSetting && t.get('available')) ||
        (t.get('term').toString() ==
          existingSetting.get('default_term').toString() &&
          t.get('available'))
      );
    });

    if (Map.isMap(firstAvailable)) {
      setSelectedTerm({
        term: firstAvailable.get('term'),
        payment: firstAvailable.get('monthly_payment'),
        term_period: firstAvailable.get('term_period'),
        available: firstAvailable.get('available'),
      });
    }
  }, [termData]);

  useEffect(() => {
    if (agreed) setValid(true);
  }, [agreed]);

  const availableTerms = !List.isList(termData)
    ? []
    : termData
        .filter((i) => i.get('available') && i.get('term_period') === 'monthly')
        .sortBy((t) => t.get('term'))
        .map((d, idx) => (
          <TermItem
            key={idx}
            term={d}
            setParentTerm={setSelectedTerm}
            parentSelectedTerm={selectedTerm}
          >
            <div className="text-sm text-center font-semibold mb-0.5">
              <strong>
                {d.get('term') === 1
                  ? 'Net 30 / 0% interest'
                  : `${d.get('term')} monthly payments`}
              </strong>
            </div>

            <div className="text-xs text-center text-secondary-gray">
              {d.get('term') === 1
                ? `One payment, due ${daysAsMonths(
                    merchant.active_promo.get('max_net_duration') + 1,
                  )} after the order is processed`
                : `${formatPercent(
                    d.get('rate'),
                  )} per month after ${daysAsMonths(
                    merchant.active_promo.get('max_net_duration'),
                  )}`}
            </div>
          </TermItem>
        ));

  const save = () => {
    if (agreed) {
      dispatch(loading(true));
      return dispatch(
        updateRechargeTerm(company, merchant, selectedTerm.term),
      )
        .then(() => dispatch(getRoute()))
        .finally(() => dispatch(loading(false)));
    } else {
      setValid(false);
    }
  };

  if (!merchant || !company || isLoading) {
    return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;
  }

  return (
    <div role="main"  className="mx-6 sm:mx-auto">
      <ProgressBars />
      <div className="my-2 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-background-success !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'circle-check']}
            className="text-success text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-3xl font-extrabold">Good to go!</div>
      </div>

      <div className="divide-y">
        <div className="text-lg sm:text-xl font-semibold pb-2">
          {formatCurrency(company.credit_line.get('amount'))} total available
          credit
        </div>

        <div className="text-xs sm:text-sm text-secondary-gray py-2">
          Please select a default term length for future {merchant.name}{' '}
          replenishment purchases
        </div>

        <div className="text-xs sm:text-sm text-secondary-gray py-2">
          For replenishment purchases at {merchant.name}, a sales rep will place
          a Credit Key order for the purchase amount on your behalf with your
          default term length.
        </div>

        <div className="text-xs sm:text-sm text-secondary-gray pt-2">
          All terms are 0% for{' '}
          {daysAsMonths(merchant.active_promo.get('max_net_duration'))}. No
          early repayment fees.
        </div>
      </div>

      <div className="my-2 sm:my-3 flex flex-col sm:grid sm:grid-cols-2 sm:gap-2">{availableTerms}</div>

      <div className="text-left mb-2">
        <div className="field flex flex-col justify-center">
          <div className="flex items-center">
            <input
              type="checkbox"
              className={classNames(
                'h-4 w-4 rounded border-tertiary-gray focus:ring-primary-blue',
                { 'border-error': !valid },
              )}
              id="agree"
              name="agree"
              role="agree"
              onChange={() => setAgreed(!agreed)}
              value={agreed}
              checked={agreed}
            />
            <label htmlFor="agree" className="ml-4 text-xs sm:text-sm text-secondary-gray">
              I hereby agree to allow {merchant.name} representatives to
              transact on my behalf for any part of my available Credit Key
              balance, including orders up to my entire{' '}
              {formatCurrency(company.credit_line.get('amount'))} credit limit.
            </label>
          </div>
          {!valid && <div className={formStyles.validation}>* Required</div>}
        </div>
      </div>

      {(!selectedTerm.term || selectedTerm.term === 0) && (
        <div className="text-sm text-error mt-1">
          Please select a default term above.
        </div>
      )}

      <PrimaryButton
        buttonText="Confirm and Complete"
        buttonColor='bg-primary-blue'
        hoverClass='hover:bg-primary-blue-hover'
        disabled={isLoading || !selectedTerm?.term}
        isLoading={isLoading}
        onClick={save}
      />
    </div>
  );
}
