import { receiveEntity } from './entities';

const path = resource => {
  if (!resource) resource = '';
  return { resource: 'api/companies/' + resource };
}

export const validateEin = ein => {
  return (dispatch, getState, api) => {
    return api.post(path('0/validate_ein'),
      { ein: ein });
  }
}

export const updateRechargeTerm = (company, merchant, term) => {
  return (dispatch, getState, api) => {
    return api.edit(path(`${company.id}/recharge_term`),
      { merchant_id: merchant.id, term });
  }
}

export const getCompany = id => {
  return (dispatch, getState, api) => {
    return api.get(path(id))
      .then(res => dispatch(receiveEntity(res)));
  }
}

export const getMinTermRate = id => {
  return (dispatch, getState, api) => {
    return dispatch(getCredit(id, 'Terms', 'min_rate'));
  }
}

export const getCreditLineAvailable = id => {
  return (dispatch, getState, api) => {
    return dispatch(getCredit(id, 'Lines', 'pending_amount_available'));
  }
}

export const getApprovedTerms = (company_id, merchant_id) => {
  return (dispatch, getState, api) => {
    return api.post(path(company_id + '/approved_terms'), { merchant_id });
  }
}

export const getCredit = (id, type, method) => {
  return (dispatch, getState, api) => {
    if (!id || !type || !method) return Promise.reject("Missing required params");

    return api.post(path(id + '/credit'), {
      type: type,
      method: method
    });
  }
}

export const getVirtualCardDetails = (id, order_key) => {
  return (dispatch, getState, api) => {
    return api.post(path(id + '/virtual_card_details'), { order_key });
  }
}

export const updateCompany = company => {
  return (dispatch, getState, api) => {
    return api.edit(path(company.id), company)
      .then(res => dispatch(receiveEntity(res)));
  }
}
