import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromJS, Map } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import Error from '../../shared/Error';
import Heap from '../../../lib/utils/heap';
import LargePaymentModal from '../../shared/modal/LargePaymentModal';
import Loading from '../../shared/Loading';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import ProgressBars from '../../shared/ProgressBars';
import ShoppingCartRecord from '../../../records/shopping_cart';
import Term from '../../shared/Term';
import Tooltip from '../../shared/Tooltip';
import useModal from '../../../hooks/useModal';
import usePromo from '../../../hooks/usePromo';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSessionEntity from '../../../hooks/useSessionEntity';
import { asyncActions, error } from '../../../actions/general';
import { emitEvent } from '../../../lib/utils/modal';
import { getMinTermRate } from '../../../actions/companies';
import { getRoute, getSession, loading } from '../../../actions/sessions';
import {
  daysAsMonths,
  formatCurrency,
  formatPercent,
} from '../../../lib/utils/formatters';
import {
  getShoppingCart,
  updateShoppingCart,
} from '../../../actions/shopping_carts';

const heap = new Heap();

export default function Terms({ location }) {
  const dispatch = useDispatch();
  const [isRouting, setIsRouting] = useState(false);
  const { isModalActive, toggleModal } = useModal();

  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const session = useSelector((state) => state.sessions.get('session'));
  const application = useSessionEntity('application');
  const cart = useSessionEntity('shopping_cart');
  const merchant = useSessionEntity('merchant');
  const company = useSessionEntity('company');
  const user = useSessionEntity('user');
  const promo = usePromo();

  const [selectedTerm, setSelectedTerm] = useState({
    term: null,
    term_period: null,
    payment: null,
  });
  const [largeSinglePayment, setLargeSinglePayment] = useState(false);
  const [minRate, setMinRate] = useState();
  const defaultTerm = company?.recharge_settings
    ?.find((s) => {
      return s.get('merchant_id').toString() === merchant.id.toString();
    })
    ?.get('default_term');

  const setInitialTerm = (cart) => {
    if (!cart.approved_terms || selectedTerm.term)
      return Promise.reject('Invalid state');

    if (cart.approved_terms.count() > 0) {
      emitEvent(
        user,
        {
          path: location.pathname,
          approved_terms: cart.approved_terms,
          credit_line: company?.credit_line,
        },
        cart,
      );
    }

    const net30Term = cart.approved_terms.find(
      (t) => t.get('term_period') === 'days',
    );
    const termToUse = cart.num_payments || net30Term || defaultTerm;

    const firstAvailable =
      net30Term ||
      cart.approved_terms.find((t) => {
        return termToUse
          ? t.get('term') == termToUse && t.get('available')
          : t.get('available');
      });

    if (Map.isMap(firstAvailable)) {
      setSelectedTerm({
        term: firstAvailable.get('term'),
        payment: firstAvailable.get('monthly_payment'),
        term_period: firstAvailable.get('term_period'),
      });
      return Promise.resolve(defaultTerm && setLargeSinglePayment(true));
    } else {
      return Promise.reject('No valid terms');
    }
  };

  useRouteStatus(location);

  useEffect(() => {
    dispatch(asyncActions(getSession()));
  }, []);

  useEffect(() => {
    if (user) heap.identify(user.email);
  }, [user]);

  const save = (e) => {
    e.preventDefault();
    if (
      selectedTerm.term === 1 &&
      parseFloat(cart.grand_total) > parseFloat('2000.00') &&
      !largeSinglePayment
    ) {
      toggleModal();
    } else {
      setLargeSinglePayment(true);
    }
  };

  useEffect(() => {
    if (company && !minRate) {
      dispatch(loading(true));
      dispatch(asyncActions(getMinTermRate(company.id)))
        .then((res) => {
          if (res) setMinRate(res.attributes.data);
        })
        .then(() => {
          // get approved terms for states:
          //
          // 1) on no application;
          //    this means we're a returning borrower placing an order
          //
          // 2) on cart having no terms;
          //    this means a credit refresh just occured
          if (!application || cart.approved_terms.count() === 0) {
            dispatch(getShoppingCart(cart.id));
          }
        })
        .then(() => dispatch(loading(false)))
        .catch((err) => dispatch(error(err)));
    }
  }, [company]);

  useEffect(() => {
    // Set initial selected term. It can be any term row if some are
    // unavailable or a previsouly selected one.
    dispatch(loading(true));

    setInitialTerm(cart)
      .then(() => dispatch(loading(false)))
      .catch(() => dispatch(loading(false)));
  }, [cart]);

  useEffect(() => {
    if (largeSinglePayment && selectedTerm.term && selectedTerm.term !== 0) {
      setIsRouting(true);
      dispatch(loading(true));
      dispatch(getShoppingCart(cart?.id)).then((res) => {
        dispatch(
          asyncActions(
            updateShoppingCart(
              new ShoppingCartRecord(
                fromJS({
                  ...res.payload.data.attributes,
                  num_payments: selectedTerm.term,
                  payment_amount: selectedTerm.payment,
                  term_period: selectedTerm.term_period,
                }),
              ),
            ),
            getRoute(),
          ),
        ).then(() => {
          dispatch(loading(false));
          setIsRouting(false);
        });
      });
    }
  }, [largeSinglePayment]);

  const net30Term = cart.approved_terms
    .filter((i) => i.get('available') && i.get('term_period') === 'days')
    .map((d, idx) => (
      <Term
        key={idx}
        term={d}
        setParentTerm={setSelectedTerm}
        parentSelectedTerm={selectedTerm}
        cart={cart}
        promo={promo}
      />
    ));

  const pi4Term = cart.approved_terms
    .filter((i) => i.get('available') && i.get('term_period') === 'biweekly')
    .map((d, idx) => (
      <Term
        key={idx}
        term={d}
        setParentTerm={setSelectedTerm}
        parentSelectedTerm={selectedTerm}
        cart={cart}
        promo={promo}
      />
    ));

  const availableTerms = cart.approved_terms
    .filter((i) => i.get('available') && i.get('term_period') === 'monthly')
    .sortBy((t) => t.get('term'))
    .map((d, idx) => (
      <Term
        key={idx}
        term={d}
        setParentTerm={setSelectedTerm}
        parentSelectedTerm={selectedTerm}
        cart={cart}
        promo={promo}
      />
    ));

  const unavailableTerms = cart.approved_terms
    .filter((i) => !i.get('available') && i.get('term_period') === 'monthly')
    .sortBy((t) => t.get('term'))
    .map((d, idx) => (
      <Term
        key={idx}
        term={d}
        setParentTerm={setSelectedTerm}
        parentSelectedTerm={selectedTerm}
        cart={cart}
        promo={promo}
      />
    ));

  const headerSection = () => {
    if (!cart)
      return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;

    if (cart.using_virtual_card) {
      return (
        <>
          <div className="mt-4 mb-1 sm:mb-4">
            <div className="text-xl sm:text-4xl font-extrabold">
              Select Terms
            </div>
          </div>
          <div className="tracking-wide text-sm sm:text-lg mb-6">
            Choose your terms for your virtual card purchase and lock in 0%
            interest for {promo.get('max_net_duration')} days!
          </div>
        </>
      );
    } else {
      return (
        <div className="px-4 xxs:px-0">
          {cart?.status === 'approved' &&
          (!session.hasApplication() ||
            (session.hasApplication() &&
              application?.status === 'approved')) ? (
            <div className="my-4 flex items-center">
              <span className="fa-layers fa-fw text-5xl">
                <FontAwesomeIcon
                  icon="square"
                  className="text-background-success !mx-0"
                />
                <FontAwesomeIcon
                  icon={['far', 'circle-check']}
                  className="text-success text-3xl !mx-1.5"
                />
              </span>
              <div className="text-xl sm:text-4xl font-extrabold">Approved</div>
            </div>
          ) : null}

          {merchant.supports_pi4 && pi4Term && (
            <div className="sm:text-xl my-1 sm:my-3 font-semibold">
              How do you want to pay for your {formatCurrency(cart.grand_total)}{' '}
              purchase?
            </div>
          )}

          {!merchant.supports_pi4 && (
            <>
              {promo.get('max_net_payment' > 30) ? (
                <div className="sm:text-2xl my-1 sm:my-3 font-semibold">
                  No payments for {daysAsMonths(promo.get('max_net_payment'))}
                </div>
              ) : null}
              {!company.products.includes('enterprise_net_30') && (
                <div className="sm:text-2xl my-1 sm:my-3 font-semibold">
                  0% for {daysAsMonths(promo.get('max_net_duration'))}, up to{' '}
                  {formatCurrency(company.credit_line.get('amount'))}
                </div>
              )}

              {!company.products.includes('enterprise_net_30') && (
                <div className="text-xs xs:text-sm mobile:text-base">
                  <div className="font-light text-sm sm:text-lg tracking-wide mb-4">
                    <div>
                      If you choose to go beyond{' '}
                      {daysAsMonths(promo.get('max_net_duration'))}, you'll pay
                      the amount of interest specified below.
                    </div>
                  </div>
                  {cart.processing_fee > 0.0 && (
                    <div className="my-2">
                      <Tooltip
                        tooltipText={`For all Credit Key Anywhere orders there is a ${formatPercent(
                          cart?.processing_fee * 100,
                        )} service fee. Because your merchant did not elect to cover the fee, it is added on to your order amount.`}
                        triggerClass="px-1 mt-1 hover:cursor-pointer hover:text-secondary-gray"
                        containerClass="p-3 bg-gray-100 w-3/4 sm:w-1/4 tracking-wide"
                      >
                        This order includes a{' '}
                        {formatPercent(cart?.processing_fee * 100)} Service Fee
                        <FontAwesomeIcon
                          icon={['fal', 'info-circle']}
                          className="ml-1 mt-0.5"
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      );
    }
  };

  if (!company || !merchant || !cart || !promo || defaultTerm) {
    return <Loading className="w-14 h-14" containerClass="my-24 sm:my-16" />;
  }

  return (
    <div id="content" role="main" className="mx-6 sm:mx-auto md:mb-6">
      <ProgressBars />
      <div>
        <Error />

        {headerSection()}
      </div>

      {company.products.includes('enterprise_net_30') ? (
        <div className="mx-5 sm:mx-auto">{net30Term}</div>
      ) : (
        <div className="mx-auto">
          {merchant.supports_pi4 && pi4Term}
          {unavailableTerms}
          {availableTerms}
        </div>
      )}

      <LargePaymentModal
        cart={cart}
        isModalActive={isModalActive}
        merchant={merchant}
        setLargeSinglePayment={setLargeSinglePayment}
        toggleModal={toggleModal}
      />

      {cart?.approved_terms.count() !== 0 &&
        (!selectedTerm.term || selectedTerm.term === 0) && (
          <div className="text-xs sm:text-sm text-error my-2">
            Please select a term above.
          </div>
        )}

      <PrimaryButton
        buttonText="Continue"
        buttonColor="bg-primary-blue"
        hoverClass="hover:bg-primary-blue-hover"
        disabled={isLoading || isRouting}
        isLoading={isLoading || isRouting}
        onClick={save}
      />
    </div>
  );
}
